<div>
  <form [formGroup]="reportBugForm" (ngSubmit)="submit()">
    <div>
      <p for="location">Where did you find the bug?</p>
      <p-dropdown
        [options]="locationOptions"
        name="location"
        [styleClass]="'w-full'"
        class="mt-2"
        formControlName="location"
        optionLabel="name"
        [showClear]="true"
        placeholder="Choose a location"
      ></p-dropdown>
      <small class="p-error" *ngIf="submitted && !reportBugForm.value.location"
        >Location of the problem is required.</small
      >
    </div>
    <div>
      <p for="title">Summary</p>
      <input
        type="text"
        pInputText
        id="title"
        autofocus
        class="w-full"
        max="100"
        min="0"
        formControlName="title"
      />
      <small class="p-error" *ngIf="submitted && !reportBugForm.value.title"
        >Summary of the problem is required.</small
      >
    </div>
    <div>
      <p for="description">What went wrong?</p>
      <textarea
        formControlName="description"
        pInputTextarea
        id="description"
        required
        class="describe-problem-texarea"
        rows="10"
        [autoResize]="true"
      ></textarea>
      <small
        class="p-error"
        *ngIf="submitted && !reportBugForm.value.description"
        >A small description of the problem is required.</small
      >
    </div>
    <div>
      <p>Attach file</p>
      <p-fileUpload
        #fileUpload
        class="upload-button"
        mode="advanced"
        [customUpload]="true"
        chooseLabel="Choose files"
        accept=".xls, .xlsx, .xlsm, .csv, image/*"
        [multiple]="true"
        [showUploadButton]="false"
        [showCancelButton]="false"
      ></p-fileUpload>
    </div>
    <div class="flex flex-column gap-3 mt-5 mb-5">
      <p-checkbox
        [formControl]="$any(reportBugForm.controls['isActive'])"
        [binary]="true"
        name="isActive"
        label="Include data about your current environment, like the browser and screen resolution. This helps us understand your feedback better."
      ></p-checkbox>
      <small class="p-error" *ngIf="submitted && !reportBugForm.value.isActive"
        >You need to check the checkbox</small
      >
    </div>
    <div class="sentance" (click)="toggleText()">
      What is included in the data about my current environment?
    </div>
    <div class="info" *ngIf="showText">
      <p>*User-Agent*: {{ userAgent }}</p>
      <p>*Screen Resolution*: {{ screenResolution }}</p>
    </div>
    <div class="mt-5">
      <button pButton pRipple type="submit">Submit</button>
    </div>
  </form>
</div>
