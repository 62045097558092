import { SvgWrapperComponentModule } from './../svg-wrapper/svg-wrapper.component';
import { TreeNode } from 'primeng/api';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MenuService } from './../../services/menu.service';
import { ISidebarMenu } from './../../interfaces/sidebar-menu.interface';
import { Component, NgModule, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IsActiveMatchOptions } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PrimengModule } from '../../primeng/primeng.module';
import { ReportBugPopUpService } from '../../services/report-bug-popup.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pp-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  listItems: ISidebarMenu[] = [];
  menuInfoColapse: boolean = false;
  colapse: boolean = false;
  activeRoot!: ISidebarMenu | null;
  subscription!: Observable<ISidebarMenu[]>;
  options: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'ignored',
    paths: 'subset',
    fragment: 'ignored'
  };
  mobile: boolean = false;

  constructor(
    private menuService: MenuService,
    private reportBugPopUpService: ReportBugPopUpService
  ) {}

  expandNode(node: TreeNode): void {
    const expand: boolean = node.expanded ? false : true;
    this.menuService.expandRecursive(node, expand);
  }

  colapseMenu(): void {
    this.colapse = !this.colapse;
    this.menuInfoColapse = !this.menuInfoColapse;
    if (this.colapse) {
      this.listItems.forEach((node: ISidebarMenu) => {
        this.menuService.expandRecursive(node, false);
      });
    } else {
      this.menuService.expandRecursive(
        this.menuService.activeRoot as ISidebarMenu,
        true
      );
    }
  }

  showMenuExpand(): void {
    if (this.colapse) {
      this.colapse = !this.colapse;
      this.menuInfoColapse = !this.menuInfoColapse;
      this.menuService.expandRecursive(
        this.menuService.activeRoot as ISidebarMenu,
        true
      );
    }
  }

  ngOnInit(): void {
    this.getMenuItemsFromRoute();
    this.getMenuItemsFromStorage();
  }

  getMenuItemsFromRoute(): void {
    this.menuService
      .subscribeRouter()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: ISidebarMenu[]) => {
          this.listItems = data;
          this.activeRoot = this.menuService.activeRoot;
          localStorage.setItem('menu-items', JSON.stringify(data));
          if (this.activeRoot) {
            localStorage.setItem(
              'active-root',
              JSON.stringify(this.activeRoot)
            );
          }
        }
      });
  }

  getMenuItemsFromStorage(): void {
    const menuItems: string | null = localStorage.getItem('menu-items');
    const activeNode: string | null = localStorage.getItem('active-root');

    if (menuItems) {
      this.listItems = JSON.parse(menuItems);
    }

    if (activeNode) {
      this.activeRoot = JSON.parse(activeNode);
    }
  }

  openReportBugPopUp(mobile: boolean): void {
    this.reportBugPopUpService.open(mobile);
  }
}
@NgModule({
  imports: [CommonModule, PrimengModule, SvgWrapperComponentModule],
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  providers: [DialogService, DynamicDialogRef]
})
export class SidebarComponentModule {}
