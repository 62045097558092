import { HolidayRequestControllerService, RequestResponseTypeDTO } from 'src/app/core/api/v1';
import { Injectable } from '@angular/core';


import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FiltersResolver  {
  constructor(
    private readonly requestsService: HolidayRequestControllerService
  ) {}

  resolve(): Observable<RequestResponseTypeDTO[]> {
    return this.requestsService.getResponseType();
  }
}
