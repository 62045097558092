<div class="flex h-screen overflow-x-hidden">
  <div [ngSwitch]="(mobile$ | async)!">
    <pp-sidebar *ngSwitchCase="false"></pp-sidebar>
    <pp-burger-menu *ngSwitchCase="true"></pp-burger-menu>
  </div>
  <div class="flex flex-column align-items-center w-full overflow-y-auto">
    <pp-navbar class="w-full z-1" [mobile]="(mobile$ | async)!"></pp-navbar>
    <div class="py-4 w-full overflow-x-hidden flex-grow-1">
      <router-outlet></router-outlet>
     </div>
  </div>
</div>
