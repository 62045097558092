import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '../services/account/account.service';
import { AuthService } from '../services/login/auth.service';
import { AuthorityEnum } from '../enums/authority.enum';
import { RoutesEnum } from '../../shared/enums/routes.enum';
import { UserDTO } from '../api/v1';


@Injectable({ providedIn: 'root' })
export class LoginGuard  {
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.accountService.getAccountInfo().pipe(
      map((account: UserDTO | null) => {
        if (account && account.isActive) {
          if (account.roleName === AuthorityEnum.ADMIN) {
            this.router.navigate([RoutesEnum.ADMIN_DASHBOARD_PATH]);
          } else {
            this.router.navigate([RoutesEnum.DASHBOARD_REGULAR_USER_PATH]);
          }
        }
        return true;
      })
    );
  }
}
