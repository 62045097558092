<button
  pButton
  pRipple
  type="button"
  class="p-button-text z-2 absolute burger-position"
  (click)="showMenu()"
  icon="pi pi-align-justify"
></button>
<p-sidebar
  styleClass="burger-menu-sidebar"
  [(visible)]="visibleMenu"
  [baseZIndex]="10000"
  (onHide)="hideMenu()"
  transitionOptions="300ms cubic-bezier(0, 0, 0.2, 1)"
>
  <ng-template pTemplate="header">
    <div class="flex justify-content-center">
      <img
        src="../../../../assets/images/logos/logo_white.svg"
        class="logo-expand"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column">
      <p-tree [value]="listItems" styleClass="tree-node-menu">
        <ng-template let-node pTemplate="default">
          <div
            class="flex align-items-center item"
            [ngClass]="{
              'text-white':
                rla.isActive ||
                (activeRoot?.name === node.name && node.children?.length > 0)
            }"
            routerLinkActive
            #rla="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="node.route"
            (mouseover)="node.isHovered = true"
            (mouseleave)="node.isHovered = false"
            (click)="expandNode(node)"
          >
            <span
              class="icon flex justify-content-center w-2rem"
              *ngIf="!node.isChild"
            >
              <pp-svg-wrapper
                [fill]="
                  rla.isActive || activeRoot?.name === node.name
                    ? '#fff'
                    : '#a3a4a7'
                "
                [source]="node.icon"
                [height]="node.height"
                [width]="node.width"
              ></pp-svg-wrapper>
            </span>
            <span
              [ngClass]="{
                'ml-7': node.isChild
              }"
              class="ml-4"
            >
              {{ node.label }}
            </span>
          </div>
        </ng-template>
      </p-tree>
      <div class="flex report-bug item scrollbar">
        <div
          class="flex flex-row mb-4 report-bug-button"
          (click)="openReportBugPopUp(mobile)"
        >
          <span class="icon flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="flag"
            >
              <path
                d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24V64 350.5 400v88c0 13.3 10.7 24 24 24s24-10.7 24-24V388l80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52V24zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8V334.7l-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5v-237z"
              />
            </svg>
          </span>
          <span class="report-text mt-1 ml-4"> Report a problem </span>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div
      class="flex align-items-center item px-6 py-3 border-top-1 border-color"
      (click)="logout()"
    >
      <span class="icon w-2 flex justify-content-center">
        <pp-svg-wrapper
          fill="#a3a4a7"
          source="logout"
          height="26"
          width="26"
        ></pp-svg-wrapper>
      </span>
      <span class="ml-4"> Logout </span>
    </div>
  </ng-template>
</p-sidebar>
