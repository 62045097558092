import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';
import { PrimengModule } from '../../primeng/primeng.module';

@Component({
  selector: 'pp-svg-wrapper',
  templateUrl: './svg-wrapper.component.html',
  styleUrls: ['./svg-wrapper.component.scss']
})
export class SvgWrapperComponent {
  @Input() source!: string;
  @Input() fill!: string;
  @Input() width!: string;
  @Input() height!: string;
}
@NgModule({
  imports: [CommonModule, PrimengModule],
  declarations: [
    SvgWrapperComponent
  ],
  exports: [SvgWrapperComponent],
  providers: []
})
export class SvgWrapperComponentModule {}
