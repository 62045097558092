import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { BugControllerService, UserDTO } from 'src/app/core/api/v1';
import { AccountService } from 'src/app/core/services/account/account.service';
import { PrimengModule } from 'src/app/shared/primeng/primeng.module';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pp-report-bug',
  templateUrl: './report-bug.component.html',
  styleUrls: ['./report-bug.component.scss']
})
export class ReportBugComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload!: FileUpload;

  currentUser!: UserDTO | null;
  reportBugForm!: FormGroup;
  submitted: boolean = false;
  mobile: boolean = false;
  showText: boolean = false;
  userAgent: string = window.navigator.userAgent;
  screenResolution: string = `${window.screen.width} x ${window.screen.height}`;
  locationOptions: { name: string }[] = [];
  selectedLocation!: { name: string };

  constructor(
    private formBuilder: FormBuilder,
    private ref: DynamicDialogRef,
    private bugService: BugControllerService,
    private messageService: MessageService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getLocations();
    this.createBugForm();
  }

  createBugForm(): void {
    this.reportBugForm = this.formBuilder.group({
      location: [null, Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      isActive: [false]
    });
  }

  getCurrentUser(): void {
    this.accountService
      .getAccountInfo()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (user: UserDTO | null) => {
          this.currentUser = user;
        }
      });
  }

  getLocations(): void {
    const menuItems: any = localStorage.getItem('menu-items');
    const locations: any = menuItems ? JSON.parse(menuItems) : null;

    if (locations) {
      locations.forEach((location: any) => {
        if (location.children && location.children.length > 0) {
          location.children.forEach((child: any) => {
            if (child.visibleTo.includes(this.currentUser?.roleName)) {
              this.locationOptions.push({ name: location.label + ' - ' + child.label });
            }
          });
        } else {
          if (location.visibleTo.includes(this.currentUser?.roleName)) {
            this.locationOptions.push({ name: location.label });
          }
        }
      });
    }
  }

  submit(): void {
    this.submitted = true;

    if (this.reportBugForm.valid && this.reportBugForm.value.isActive) {
      const formatDescription: string = this.reportBugForm.value.description.replace(/["\\]/g, '').replace(/\n/g, ' ');
      const formatTitle: string = this.reportBugForm.value.title.replace(/["\\]/g, '').replace(/\n/g, ' ');
      const bugDTO: any = {
        description: formatDescription,
        title: formatTitle,
        pageUrl: this.reportBugForm.value.location.name,
        userAgent: this.userAgent,
        screenResolution: this.screenResolution,
        createdBy: this.currentUser?.email,
        createdDate: new Date()
      };
      const files: Blob[] = this.prepareFilesToUpload();

      this.bugService.reportBug(
        bugDTO,
        files
      ).pipe(untilDestroyed(this)).subscribe(() => {
        this.ref.close();
        this.reportBugForm.reset();
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'The bug has been reported',
          life: 3000
        });
      });
    }
  }

  prepareFilesToUpload(): Blob[] {
    const files: Blob[] = [];

    if (this.fileUpload.files?.length > 0) {
      this.fileUpload.files.forEach((file: File) => {
        const blob: Blob = new File([file], file.name, { type: file.type });
        files.push(blob);
      });
    }

    return files;
  }

  toggleText(): void {
    this.showText = !this.showText;
  }

}

@NgModule({
  imports: [
    PrimengModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: '',
        component: ReportBugComponent
      }
    ])
  ],
  declarations: [ReportBugComponent],
  exports: [ReportBugComponent],
  providers: [MessageService]
})
export class ReportBugComponentModule { }
