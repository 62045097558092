import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationControllerService, UserDTO } from '../../api/v1';
import { AuthorityEnum } from '../../enums/authority.enum';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private currentUser$: ReplaySubject<UserDTO | null> =
    new ReplaySubject<UserDTO | null>(1);

  constructor(
    private http: HttpClient,
    private authenticationControllerService: AuthenticationControllerService
  ) { }

  /**
   * Check if the current user has any of the specified authorities.
   *
   * Usage:
   * const hasAuthority = await accountService.hasAnyAuthority([AuthorityEnum.ADMIN, AuthorityEnum.REGULAR]);
   * if (hasAuthority) {
   *   // The user has one of the specified roles
   * }
   */
  async hasAnyAuthority(
    authorities: AuthorityEnum[] | AuthorityEnum
  ): Promise<boolean> {
    const user: UserDTO | null = await firstValueFrom(this.currentUser$);
    if (!user) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    const userRole: string = user.roleName || AuthorityEnum.REGULAR;
    return authorities.includes(userRole as AuthorityEnum);
  }

  /**
   * Retrieve the account information of the current user.
   *
   * Usage:
   * accountService.getAccountInfo().subscribe((accountInfo) => {
   *   // Do something with the account info
   * });
   */
  getAccountInfo(forceUpdate?: boolean): Observable<UserDTO | null> {
    if (!this.currentUser$.observed || !this.isAuthenticated() || forceUpdate) {
      this.fetchAndUpdateAccountInfo();
    }

    return this.currentUser$.asObservable();
  }

  /**
   * Check if the user is authenticated.
   *
   * Usage:
   * const isAuthenticated = await accountService.isAuthenticated();
   * if (isAuthenticated) {
   *   // The user is authenticated
   * }
   */
  async isAuthenticated(): Promise<boolean> {
    const user: UserDTO | null = await lastValueFrom(this.currentUser$);
    return user !== null;
  }

  // Fetch account information from the authentication service and update the currentUser$ subject
  private fetchAndUpdateAccountInfo(): void {
    lastValueFrom(this.authenticationControllerService.getAccount())
      .then((accountInfo: null | UserDTO) => {
        this.currentUser$.next(accountInfo);
      })
      .catch(() => {
        this.currentUser$.next(null);
      });
  }
}
