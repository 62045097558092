import { PrimengModule } from 'src/app/shared/primeng/primeng.module';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponentModule } from './shared/layout/layout.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GlobalErrorHandler } from './core/errors/global-error-handler';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ApiModule, Configuration, ConfigurationParameters } from './core/api/v1';
import { environment } from '../config/environments/environment';
import { WithCredentialsInterceptor } from './core/interceptors/with-credentials.interceptor';
import { NgHttpLoaderModule } from 'ng-http-loader';

import { SessionExpirationInterceptor } from './core/interceptors/session-expiration.interceptor';

export const apiConfigFactory = (): Configuration => {
  const params: ConfigurationParameters = {
    basePath: `${environment.apiBaseURL}`
  };

  return new Configuration(params);
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutComponentModule,
    ApiModule.forRoot(apiConfigFactory),
    HttpClientModule,
    PrimengModule,
    NgHttpLoaderModule.forRoot()
  ],
  exports: [PrimengModule],
  providers: [
    MessageService,
    ConfirmationService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionExpirationInterceptor,
      multi: true
    },
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
