import { UserControllerService } from './../../core/api/v1/api/userController.service';
import { UserEntitlementDTO } from 'src/app/core/api/v1';
import { Injectable } from '@angular/core';


import { Observable, switchMap } from 'rxjs';
import { AccountService } from 'src/app/core/services/account/account.service';

@Injectable({
  providedIn: 'root'
})
export class HolidaysResolver  {
  constructor(
    private readonly userService: UserControllerService,
    private readonly accountService: AccountService
  ) { }

  resolve(): Observable<UserEntitlementDTO> {
    return this.accountService.getAccountInfo().pipe(
      switchMap(() => {
        return this.userService.getEntitlement();
      })
    );
  }
}
