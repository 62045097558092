import { DisplayService } from 'src/app/shared/services/display.service';
import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  position:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
    | 'center' = 'top-right';
  title: string = 'people-platform-fe';
  constructor(private displayService: DisplayService) {}

  ngOnInit(): void {
    this.displayService.isMobile$.pipe(untilDestroyed(this)).subscribe({
      next: (result: boolean) => {
        if (result) {
          this.position = 'top-center';
        }
      }
    });
  }
}
