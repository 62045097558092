/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkQuotaDTO } from './workQuotaDTO';
import { CompanyDTO } from './companyDTO';
import { TeamDTO } from './teamDTO';
import { PositionDTO } from './positionDTO';


export interface UserDTO { 
    id?: number;
    lastName?: string;
    firstName?: string;
    email?: string;
    position?: PositionDTO;
    company?: CompanyDTO;
    dateOfBirth?: string;
    hireDate?: string;
    managerId?: number;
    contractualVacationDays?: number;
    flexiVacationDays?: number;
    remainingDaysLastYear?: number;
    mentalHealthDays?: number;
    workQuota?: WorkQuotaDTO;
    arrivalTime?: string;
    isActive?: boolean;
    roleName?: string;
    role?: UserDTO.RoleEnum;
    additionalVacationDays?: number;
    teams?: Array<TeamDTO>;
    departureDate?: string;
    image?: Array<string>;
    phoneNumber?: string;
    managerName?: string;
    accountingId?: string;
    leaveReason?: UserDTO.LeaveReasonEnum;
}
export namespace UserDTO {
    export type RoleEnum = 'ADMIN' | 'MANAGER' | 'REGULAR';
    export const RoleEnum = {
        Admin: 'ADMIN' as RoleEnum,
        Manager: 'MANAGER' as RoleEnum,
        Regular: 'REGULAR' as RoleEnum
    };
    export type LeaveReasonEnum = 'OWN_DECISION' | 'NO_ALLOCATION' | 'POOR_PERFORMANCE' | 'PROBATION_NOT_PASSED';
    export const LeaveReasonEnum = {
        OwnDecision: 'OWN_DECISION' as LeaveReasonEnum,
        NoAllocation: 'NO_ALLOCATION' as LeaveReasonEnum,
        PoorPerformance: 'POOR_PERFORMANCE' as LeaveReasonEnum,
        ProbationNotPassed: 'PROBATION_NOT_PASSED' as LeaveReasonEnum
    };
}


