import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {
  public isMobile$: Observable<boolean> = this.breakpointObserver
    .observe(['(max-width:  767px)'])
    .pipe(
      map((result: BreakpointState) => result.matches),
      shareReplay()
    );

  public isMobile: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isMobile$.subscribe((res: boolean) => (this.isMobile = res));
  }
}
