import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReportBugComponent } from 'src/app/views/report-bug/report-bug.component';

@Injectable({
  providedIn: 'root'
})
export class ReportBugPopUpService {
  ref: DynamicDialogRef | undefined;

  constructor(private dialogService: DialogService) { }

  open(mobile: boolean): void {
    this.ref = this.dialogService.open(ReportBugComponent, {
      header: 'Raise a bug',
      style: {
        height: mobile ? '29rem' : '90%',
        width: mobile ? '21rem' : '50%'
      },
      maximizable: mobile ? true : false
    });

    // next 4 lines are a workaround for expressionChangedAfterItHasBeenCheckedError in dev mode
    const dialogRef: any = this.dialogService.dialogComponentRefMap.get(this.ref);
    const dynamicComponent: any = dialogRef?.instance as DynamicDialogComponent;
    const ariaLabelledBy: any = dynamicComponent.getAriaLabelledBy();
    dynamicComponent.getAriaLabelledBy = (): any => ariaLabelledBy;

    this.ref.onClose.subscribe();
  }
}
