import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageCVStateService {
  private addCvSubject: Subject<boolean> = new Subject<boolean>();

  public setAddCv(state: boolean): void {
    this.addCvSubject.next(state);
  }
  public getAddCv(): Observable<boolean> {
    return this.addCvSubject.asObservable();
  }
}
