<div
  class="navbar flex px-2 lg:px-4 align-items-center justify-content-between"
>
  <div
    *ngIf="mobile"
    [ngClass]="{
      'w-1': mobile
    }"
  ></div>
  <div class="flex">
    <p
      class="title"
      [ngClass]="{
        'my-auto text-center': mobile
      }"
    >
      {{ navbarName }}
    </p>
  </div>
  <div
    class="flex align-items-center"
    [ngClass]="{
      'w-1': mobile
    }"
  >
    <div class="flex align-items-center user-icon" *ngIf="!mobile">
      <img src="../../../../assets/icons/profile.svg" />
      <img
        class="cursor-pointer"
        src="../../../../assets/icons/arrow.svg"
        (click)="menu.toggle($event)"
      />
    </div>
    <p-menu #menu [popup]="true" [model]="options"></p-menu>
  </div>
</div>
