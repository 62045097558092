<div
  class="flex flex-column h-full scrollbar"
  [ngClass]="['menu', colapse ? 'colapse' : 'expand']"
>
  <div
    class="cursor-pointer flex align-items-center sticky top-0 logo-container"
    [ngClass]="{
      'justify-content-around': !colapse,
      'justify-content-center': colapse
    }"
  >
    <div class="py-4">
      <img
        [src]="
          colapse
            ? '../../../../assets/images/logos/logo_simple.svg'
            : '../../../../assets/images/logos/logo_white.svg'
        "
        [ngClass]="['logo', colapse ? 'colapse' : 'expand']"
        (click)="colapseMenu()"
      />
    </div>
    <div>
      <button
        pButton
        pRipple
        type="button"
        class="p-button-text text-200 justify-content-center toggle-sidebar-button"
        [ngClass]="{
          hidden: colapse
        }"
        icon="pi pi-angle-double-left"
        (click)="colapseMenu()"
      ></button>
    </div>
  </div>

  <p-tree
    [value]="listItems"
    styleClass="tree-node-menu"
    [ngClass]="{
      'delete-button align-icons': colapse
    }"
  >
    <ng-template let-node pTemplate="default">
      <div
        (click)="expandNode(node)"
        class="flex align-items-center item hover:text-white"
        routerLinkActive
        #rla="routerLinkActive"
        [routerLinkActiveOptions]="options"
        [routerLink]="node.route"
        [ngClass]="{
          'justify-content-center': colapse,
          'text-white':
            rla.isActive ||
            (activeRoot?.name === node.name && node.children?.length > 0),
          'align-icons': colapse
        }"
        (mouseover)="node.isHovered = true"
        (mouseleave)="node.isHovered = false"
      >
        <span
          class="icon flex justify-content-center cursor-pointer w-2rem"
          *ngIf="!node.isChild"
          (click)="showMenuExpand()"
        >
          <pp-svg-wrapper
            [fill]="
              node.isHovered || rla.isActive || activeRoot?.name === node.name
                ? '#fff'
                : '#a3a4a7'
            "
            [source]="node.icon"
            [height]="node.height"
            [width]="node.width"
          ></pp-svg-wrapper>
        </span>
        <span
          class="cursor-pointer"
          [ngClass]="{
            'menu-info-colapse': menuInfoColapse,
            'menu-info-expand animated fadeInRight': !menuInfoColapse,
            'white-space-normal child-margin': node.isChild,
            'ml-3': !node.isChild
          }"
        >
          {{ node.label }}
        </span>
      </div>
    </ng-template>
  </p-tree>
  <div
    class="flex justify-content-center align-items-end report-bug h-full item"
  >
    <div
      class="flex flex-row mb-4 report-bug-button"
      (click)="openReportBugPopUp(mobile)"
    >
      <span class="icon flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          class="flag"
        >
          <path
            d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24V64 350.5 400v88c0 13.3 10.7 24 24 24s24-10.7 24-24V388l80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52V24zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8V334.7l-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5v-237z"
          />
        </svg>
      </span>
      <span class="report-text mt-1 {{ colapse ? 'hidden' : '' }}">
        Report a problem
      </span>
    </div>
  </div>
</div>
