import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { ILogOut } from '../../interfaces/account.interface';
import { AuthEnum } from './auth.enum';
import { environment } from '../../../../config/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // set maintenanceMode to true to show the maintenance page
  // and uncomment line from login() to give access for some users when maintenance mode is on
  maintenanceMode: boolean = false;
  adminMode!: boolean;

  private path: string = environment.apiBaseURL;

  constructor(private http: HttpClient) {}

  login(): void {
    // uncomment next line to give access for some users when maintenance mode is on
    // this.adminMode = Boolean(localStorage.getItem('adminMode'));
    if (
      (this.maintenanceMode && this.adminMode) ||
      (!this.maintenanceMode && !this.adminMode)
    ) {
      window.location.href = `${this.path}/${AuthEnum.AZURE_LOGIN_PATH}`;
    }

    if (this.maintenanceMode && !this.adminMode) {
      window.location.href = '/';
    }
  }

  logout(): void {
    lastValueFrom(
      this.http.post<ILogOut>(`${this.path}/${AuthEnum.LOGOUT_PATH}`, {})
    )
      .then(() => {
        location.href = '/';
      })
      .catch(() => {
        window.location.reload();
      });
  }
}
