import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class SessionExpirationInterceptor implements HttpInterceptor {


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any) => {

        // There may be other events besides the response.
        if (err.status === 401 && !['/', '/login'].includes(location.pathname)) {

          location.href = '/';
        }
        return next.handle(req);
      })
    );
  }

}
