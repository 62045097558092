import { NavbarComponentModule } from './../components/navbar/navbar.component';
import { SidebarComponentModule } from './../components/sidebar/sidebar.component';
import { DisplayService } from 'src/app/shared/services/display.service';
import { BurgerMenuComponentModule } from './../components/burger-menu/burger-menu.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { PrimengModule } from '../primeng/primeng.module';
import { Observable } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pp-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  mobile$: Observable<boolean> = this.displayService.isMobile$;

  constructor(private displayService: DisplayService) {}
}

@NgModule({
  imports: [
    CommonModule,
    PrimengModule,
    SidebarComponentModule,
    BurgerMenuComponentModule,
    NavbarComponentModule
  ],
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
  providers: []
})
export class LayoutComponentModule {}
