import { AccountService } from './../../core/services/account/account.service';
import { IResult } from './../interfaces/result.interface';
import { LocationDTO } from './../../core/api/v1/model/locationDTO';
import { LocationControllerService } from './../../core/api/v1/api/locationController.service';
import { NavigationEnd, Router, Scroll, Event } from '@angular/router';
import { listItemsMenu } from '../lists/sidebar-menu-items-list.constants';
import { ISidebarMenu } from './../interfaces/sidebar-menu.interface';
import {
  Observable,
  switchMap,
  BehaviorSubject,
  map,
  tap,
  concatMap,
  filter
} from 'rxjs';
import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { AuthorityEnum } from '../../core/enums/authority.enum';
import { IActiveNode } from '../interfaces/active-node.interface';
import { UserDTO } from '../../core/api/v1';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  set activeNode(value: ISidebarMenu | null) {
    this.activeNodeSubject.next(value);
  }

  get activeNode(): ISidebarMenu | null {
    return this.activeNode;
  }

  set activeRoot(value: ISidebarMenu | null) {
    this._activeRoot = value;
  }

  get activeRoot(): ISidebarMenu | null {
    return this._activeRoot;
  }

  public activeNodeSubject: BehaviorSubject<ISidebarMenu | null> =
    new BehaviorSubject<ISidebarMenu | null>({} as ISidebarMenu);

  public _activeNode: Observable<ISidebarMenu | null> =
    this.activeNodeSubject.asObservable();

  private _activeRoot!: ISidebarMenu | null;
  private listItems!: ISidebarMenu[];

  constructor(
    private locationService: LocationControllerService,
    private router: Router,
    private readonly accountService: AccountService
  ) {
    this.listItems = listItemsMenu;
  }

  expandRecursive(node: TreeNode, isExpand: boolean): void {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode: TreeNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }

  checkForVisibility(
    list: ISidebarMenu[],
    role: AuthorityEnum
  ): ISidebarMenu[] {
    return list.filter((item: ISidebarMenu) => {
      const hasRight: boolean = item.visibleTo.includes(role);
      if (hasRight && item.children) {
        item.children = this.checkForVisibility(item.children, role);
      }
      return hasRight;
    });
  }

  subscribeRouter(): Observable<ISidebarMenu[]> {
    return this.router.events.pipe(
      map((e: Event) => {
        return e instanceof Scroll ? e.routerEvent : e;
      }),
      filter((e: Event) => {
        return e instanceof NavigationEnd;
      }),
      switchMap((e: Event) => {
        return this.accountService.getAccountInfo().pipe(
          concatMap((user: UserDTO | null) => {
            return this.getMenuItems().pipe(
              map((item: ISidebarMenu[]) => {
                return this.checkForVisibility(item, user?.roleName as AuthorityEnum);
              }),
              tap((list: ISidebarMenu[]) => {
                if (e instanceof NavigationEnd) {
                  const urlActive: string = e.urlAfterRedirects.split('?')[0];
                  const active: IResult = this.detectActiveNode(
                    list,
                    urlActive,
                    'route'
                  );
                  this.activeRoot = active.parent_child;
                  this.activeNode = active.child
                    ? active.child
                    : active.parent_child;
                  list.forEach((node: ISidebarMenu) => {
                    if (node !== this.activeRoot) {
                      this.expandRecursive(node, false);
                    } else {
                      this.expandRecursive(node, true);
                    }
                  });
                }
              })
            );
          })
        );
      })
    );
  }

  private getMenuItems(): Observable<ISidebarMenu[]> {
    return this.locationService.getLocationType().pipe(
      concatMap(() => {
        const ids: LocationDTO.LocationTypeEnum[] = [LocationDTO.LocationTypeEnum.Nomad, LocationDTO.LocationTypeEnum.Mixed];
        return this.locationService.getAllLocation(ids).pipe(
          map((data: LocationDTO[]) => {
            const nomadNode: ISidebarMenu[] | undefined = this.listItems.find(
              (menu: ISidebarMenu) => menu.name === 'nomad-offices'
            )?.children;
            if (nomadNode && nomadNode.length === 0) {
              data.forEach((elem: LocationDTO) => {
                if (elem.name && elem.isActive) {
                  nomadNode?.push({
                    name: elem.name.toLowerCase().split(' ').join('-'),
                    label: elem.name,
                    isHovered: false,
                    visibleTo: [AuthorityEnum.REGULAR, AuthorityEnum.ADMIN, AuthorityEnum.MANAGER],
                    isChild: true,
                    route: `/nomad/${elem.id}`
                  });
                }
              });
            }
            const mergeItems: ISidebarMenu[] = JSON.parse(
              JSON.stringify(this.listItems)
            );
            return mergeItems;
          })
        );
      })
    );
  }

  private detectActiveNode(
    list: ISidebarMenu[],
    value: string,
    prop: keyof ISidebarMenu
  ): IResult {
    for (const item of list) {
      if (item[prop] && item[prop] === value) {
        return { parent_child: item };
      }
      if (item.children) {
        const result: IActiveNode = this.detectActiveNode(
          item.children,
          value,
          prop
        );
        if (result.parent_child) {
          return { parent_child: item, child: result.parent_child };
        }
      }
    }
    return {
      parent_child: null
    };
  }
}
