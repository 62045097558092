import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '../services/account/account.service';
import { AuthService } from '../services/login/auth.service';
import { AuthorityEnum } from '../enums/authority.enum';
import { UserDTO } from '../api/v1';


@Injectable({ providedIn: 'root' })
export class AuthorityGuard  {
  constructor(
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.accountService.getAccountInfo().pipe(
      map((account: UserDTO | null) => {
        if (account) {
          const authorities: AuthorityEnum[] = route.data['authorities'];
          const hasAnyAuthority: boolean = authorities.includes(account.roleName as AuthorityEnum);

          if ((!authorities || authorities.length === 0 || hasAnyAuthority) && account.isActive) {
            return true;
          }
          this.router.navigate(['']);
          return false;
        }
        this.authService.login();
        return false;
      })
    );
  }
}
