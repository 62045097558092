import { SvgWrapperComponentModule } from './../svg-wrapper/svg-wrapper.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuService } from './../../services/menu.service';
import { Component, NgModule, OnInit } from '@angular/core';
import { ISidebarMenu } from '../../interfaces/sidebar-menu.interface';
import { Subscription } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { PrimengModule } from '../../primeng/primeng.module';
import { ReportBugPopUpService } from '../../services/report-bug-popup.service';
import { AuthService } from 'src/app/core/services/login/auth.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pp-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent implements OnInit {
  visibleMenu!: boolean;
  listItems: ISidebarMenu[] = [];
  activeRoot!: ISidebarMenu | null;
  subscription!: Subscription;
  mobile: boolean = true;

  constructor(
    private menuService: MenuService,
    private reportBugPopUpService: ReportBugPopUpService,
    private loginService: AuthService
  ) {}

  hideMenu(): void {
    this.visibleMenu = false;
  }

  ngOnInit(): void {
    this.getMenuItemsFromRoute();
    this.getMenuItemsFromStorage();
  }

  getMenuItemsFromStorage(): void {
    const menuItems: string | null = localStorage.getItem('menu-items');
    const activeNode: string | null = localStorage.getItem('active-root');

    if (menuItems) {
      this.listItems = JSON.parse(menuItems);
    }

    if (activeNode) {
      this.activeRoot = JSON.parse(activeNode);
    }
  }

  getMenuItemsFromRoute(): void {
    this.menuService
      .subscribeRouter()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: ISidebarMenu[]) => {
          this.listItems = data.filter(
            (value: ISidebarMenu) => value.name !== 'profile-management'
          );
          this.activeRoot = this.menuService.activeRoot;
          localStorage.setItem('menu-items', JSON.stringify(data));
          if (this.activeRoot) {
            localStorage.setItem(
              'active-root',
              JSON.stringify(this.activeRoot)
            );
          }
          this.hideMenu();
        }
      });
  }

  expandNode(node: TreeNode): void {
    const expand: boolean = node.expanded ? false : true;
    this.menuService.expandRecursive(node, expand);
  }

  showMenu(): void {
    this.visibleMenu = true;
    this.menuService.expandRecursive(this.activeRoot as ISidebarMenu, true);
  }

  openReportBugPopUp(mobile: boolean): void {
    this.reportBugPopUpService.open(mobile);
  }

  logout(): void {
    this.loginService.logout();
  }
}
@NgModule({
  imports: [CommonModule, PrimengModule, SvgWrapperComponentModule],
  declarations: [BurgerMenuComponent],
  exports: [BurgerMenuComponent],
  providers: []
})
export class BurgerMenuComponentModule {}
