import { INavbarOptions } from './navbar.interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuService } from './../../services/menu.service';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { ISidebarMenu } from '../../interfaces/sidebar-menu.interface';
import { AuthService } from '../../../core/services/login/auth.service';
import { CommonModule } from '@angular/common';
import { PrimengModule } from '../../primeng/primeng.module';
import { ManageCVStateService } from 'src/app/views/profile-management/components/user-cv-list/services/manage-cv-state.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'pp-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() mobile!: boolean;

  visibleMenu: boolean = false;
  navbarName!: string;

  options: INavbarOptions[] = [
    {
      label: 'Logout',
      icon: 'pi pi-power-off',
      command: (): void => {
        this.loginService.logout();
      }
    }
  ];

  constructor(
    private menuService: MenuService,
    private loginService: AuthService,
    private manageCvStateService: ManageCVStateService
  ) {}

  ngOnInit(): void {
    this.setNavbarName();
    this.navbarNameFromCVModule();
  }

  navbarNameFromCVModule(): void {
    this.manageCvStateService
      .getAddCv()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data: boolean) => {
          if (data) {
            this.navbarName = 'Add new CV';
          } else {
            this.navbarName = 'My CV';
          }
        }
      });
  }

  setNavbarName(): void {
    this.menuService._activeNode.pipe(untilDestroyed(this)).subscribe({
      next: (data: ISidebarMenu | null) => {
        if (data) {
          this.navbarName = data.label;
        }
      }
    });
  }
}

@NgModule({
  imports: [CommonModule, PrimengModule],
  declarations: [NavbarComponent],
  exports: [NavbarComponent],
  providers: []
})
export class NavbarComponentModule {}
