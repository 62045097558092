import { MessageService } from 'primeng/api';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private messageService: MessageService, private zone: NgZone) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    console.error(error); // DON'T REMOVE THIS
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection;
    }
    if (error?.error) {
      this.zone.run(() => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: this.setMessageDetail(error),
          life: 3000
        });
      });
    }
  }

  setMessageDetail(error: HttpErrorResponse): string {
    switch (true) {
    case error?.url?.includes('bug/report'):
      return 'Bug could not be created';
    case error?.url?.includes('excel/upload-managers-teams'):
      return error?.error?.message || error?.error;
    default:
      return error?.error || error.message;
    }
  }
}
